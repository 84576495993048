<template>
	<div class="page_box">
		 
		<div class="bom_box">
			 
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" >

					<el-table-column fixed prop="id" label="日期" width="120"></el-table-column>
					 
					<el-table-column prop="id" label="打卡信息" width="">
						<el-table-column prop="checkInTime" label="上班签到" width="150"></el-table-column>
						<el-table-column prop="checkOutTime" label="下班签到" width="150"></el-table-column>
						<el-table-column prop="checkInTime1" label="上班签到" width="150"></el-table-column>
						<el-table-column prop="checkOutTime1" label="下班签到" width="150"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="下井信息" width="">
						<el-table-column prop="downCheckInTime" label="下井时间" ></el-table-column>
						<el-table-column prop="downCheckOutTime" label="出井时间" ></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="带班下井信息" width="">
						<el-table-column prop="downCheckInTime1" label="下井时间" width="200"></el-table-column>
						<el-table-column prop="downCheckOutTime1" label="出井时间" width="200"></el-table-column>
					</el-table-column>

				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import dayjs from 'dayjs';
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择
	import page from "@/components/page/page.vue"; //分页  统一的
	export default {
		components: {
			bumen_cascader,
			page,
		},
		data() {
			return {
				date_select: dayjs().format('YYYY-MM-DD'),//考勤日期
				bumen_position_info: {},//
				//所选的部门岗位信息
				
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
				},
				list: [
					
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
			
			};
		},
		computed: {},
		watch: {
	
		},

		created() {
			this.page_title = this.$route.meta.title
			// this.get_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
		 
		 
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getDayList", {
					...this.pages,
					userId:this.$route.query.id,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},
 
		},



	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
	 

		.bom_box {
			.bom_box_list {
				padding: 20px 20px 32px 20px;
				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>